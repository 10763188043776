import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { PromotionRow } from './promotion-row';
import { IVoucher } from '../types';

const TABLE_HEAD = [
  { id: 'code', label: 'Code', align: 'left', sortable: false },
  { id: 'name', label: 'Name', align: 'left', sortable: false },
  { id: 'description', label: 'Description', align: 'left', sortable: false },
  { id: 'promotionType', label: 'Promotion Type', align: 'center', sortable: false },
  { id: 'discountType', label: 'Discount Type', align: 'center', sortable: false },
  { id: 'category', label: 'Category', align: 'center', sortable: false },
  { id: 'country', label: 'Country', align: 'center', sortable: false },
  { id: 'currency', label: 'Currency', align: 'center', sortable: false },
  { id: 'totalUsage', label: 'Total Usage', align: 'center', sortable: false },
  { id: 'totalUsed', label: 'Total Used', align: 'center', sortable: false },
  { id: 'remainingUsage', label: 'Remaining Usage', align: 'center', sortable: false },
  { id: 'usagePerson', label: 'Usage/Person', align: 'center', sortable: false },
  { id: 'maximumDiscount', label: 'Maximum Discount', align: 'center', sortable: false },
  { id: 'mov', label: 'MOV', align: 'center', sortable: false },
  { id: 'startDate', label: 'Start Date', align: 'center', sortable: false },
  { id: 'endDate', label: 'End Date', align: 'center', sortable: false },
  { id: 'createdBy', label: 'Created By', align: 'center', sortable: false },
  { id: 'createdOn', label: 'Created On', align: 'center', sortable: false },
  { id: 'active', label: 'Status', align: 'center', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: IVoucher[];
  loading: boolean;
  count?: number;
}

const PromotionList = ({ tableProps, rows, loading, count }: Props) => {
  const navigation = useNavigate();

  const handleSelect = (data: IVoucher) => {
    const id = data.id;
    navigation(PATH_DASHBOARD.promotionDetail, { state: { id } });
  };
  return (
    <PaginatedTable<any>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <PromotionRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            handleSelect(row);
          }}
        />
      )}
    </PaginatedTable>
  );
};
export { PromotionList };
