import { Stack, MenuItem, TextField, InputAdornment, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { usePDF } from 'react-to-pdf';
import dayjs from 'dayjs';

import { IOrderData } from '../types';
import { Header } from '@app/sections/header';
import { useTable } from '@app/components/table';
import { Strings } from '@app/constants';
import { FormProvider, Iconify, RHFTextField } from '@app/components';
import { OrderDetails } from './order-details';
import {
  changeDeliverySlot,
  getOrderDetails,
  getSlotsByDateType,
  updateOrderStatus,
} from '../slice';
import { useDialog, useRequestState } from '@app/hooks';
import {
  selectChangeDeliverySlotState,
  selectOrderDetailState,
  selectSlotsByDateTypeState,
  selectUpdateOrderStatusState,
} from '../selectors';
import { InvoiceOrderDetails } from '@app/components/invoice';
import AddDeliverySlot from './add-delivery-slot';
import { isActiveSlot } from '@app/utils';

const OrderDetailsPage = () => {
  const tableProps = useTable();
  const dispatch = useDispatch();
  const addDeliverySlotDialog = useDialog();

  const [deliveryDate, setDeliveryDate] = useState('');

  const handleBack = () => {
    window.history.back();
  };

  const { state } = useLocation();
  useEffect(() => {
    dispatch(getOrderDetails({ id: state.id }));
  }, [dispatch, state]);

  const { data, loading } = useRequestState({
    stateSelector: selectOrderDetailState,
    errorShown: true,
  });

  const { data: slots } = useRequestState({
    stateSelector: selectSlotsByDateTypeState,
    errorShown: true,
  });

  useRequestState({
    stateSelector: selectUpdateOrderStatusState,
    successMessageShown: true,
    errorShown: true,
  });

  const { loading: changeDeliverySlotLoading } = useRequestState({
    stateSelector: selectChangeDeliverySlotState,
    successMessageShown: true,
    errorShown: true,
    onSuccess: addDeliverySlotDialog.hide,
  });

  const [selectedData, setSelectedData] = useState('');

  const methods = useForm<IOrderData>({
    defaultValues: {
      orderStatus: data?.orderStatus[data?.orderStatus?.length - 1]?.orderStatus ?? '',
    },
  });

  useEffect(() => {
    if (data) {
      methods.reset({
        orderStatus: data?.orderStatus[data?.orderStatus?.length - 1]?.orderStatus ?? '',
      });
      setSelectedData(data?.orderStatus[data?.orderStatus?.length - 1]?.orderStatus ?? '');
    }
  }, [data, methods]);

  const { toPDF, targetRef } = usePDF({ filename: 'Order-Details.pdf' });
  const handleInvoice = () => {
    toPDF();
  };

  const [isPreview, setIsPreview] = useState(false);

  const handlePreview = () => {
    setIsPreview(!isPreview);
  };

  const handleBackToDetails = () => {
    setIsPreview(!isPreview);
  };

  const activeSlot = isActiveSlot(data?.orderSlots);

  const handleSelectDate = (slotDate: string) => {
    setDeliveryDate(slotDate);
    dispatch(
      getSlotsByDateType({
        slotDate,
        slotType: activeSlot?.slotType,
      })
    );
  };

  const deliverySlot = activeSlot
    ? `${dayjs(activeSlot?.deliveryDate)
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        .format('YYYY-MM-DD')}, ${activeSlot?.name}`
    : 'NA';

  const handleOnSubmit = (slotId: number | null) => {
    dispatch(
      changeDeliverySlot({
        slotId,
        orderId: data?.id,
        deliveryDate,
      })
    );
  };

  return (
    <Stack sx={{ overflow: 'auto', height: '100%' }}>
      <Header
        backButton={!isPreview}
        title={isPreview ? 'Invoice Preview' : Strings.pageTitle.orderManagement}
        onClickBack={() => {
          handleBack();
        }}
        onClickActionButtonImport={!isPreview ? handlePreview : undefined}
        actionButtonImport={!isPreview ? 'View Invoice' : undefined}
        actionButtonTitle={isPreview ? 'Download' : undefined}
        onClickActionButton={isPreview ? handleInvoice : undefined}
        additionalButtonTitle={isPreview ? 'Back' : undefined}
        onClickAdditionalButton={isPreview ? handleBackToDetails : undefined}
      />
      <Stack>
        <FormProvider methods={methods}>
          <Stack
            flexDirection="row"
            ml={3}
            mb={3}
            width="60%"
            gap={2.5}
            sx={{ display: isPreview ? 'none' : 'visible' }}
          >
            <RHFTextField
              name="orderStatus"
              variant="outlined"
              label={Strings.detailCard.type}
              select
              sx={{ width: '30%' }}
              onChange={(event) => {
                const selectedValue = event.target.value;
                methods.setValue('orderStatus', selectedValue);
                setSelectedData(selectedValue);
                dispatch(updateOrderStatus({ orderStatus: selectedValue, id: state.id }));
              }}
            >
              {['Confirmed', 'Picked', 'Preparing', 'Deliverying', 'Delivered', 'Canceled'].map(
                (value, index, array) => (
                  <MenuItem
                    key={value}
                    value={value}
                    disabled={
                      (selectedData === 'Delivered' && value === 'Canceled') ||
                      index <= array.indexOf(selectedData)
                    }
                  >
                    {value}
                  </MenuItem>
                )
              )}
            </RHFTextField>

            <TextField
              label="Delivery Slot"
              onClick={addDeliverySlotDialog.show}
              value={deliverySlot}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton tabIndex={-1}>
                      <Iconify icon="carbon:delivery" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '65%' }}
            />
          </Stack>
        </FormProvider>
        <Stack ref={targetRef} display={isPreview ? 'visible' : 'none'}>
          <InvoiceOrderDetails details={data} />
        </Stack>
        <Stack display={isPreview ? 'none' : 'visible'}>
          <OrderDetails data={data} tableProps={tableProps} loading={loading} />
        </Stack>
      </Stack>
      {addDeliverySlotDialog.visible && (
        <AddDeliverySlot
          open
          onClose={addDeliverySlotDialog.hide}
          onSelectDate={handleSelectDate}
          slots={slots}
          activeSlot={activeSlot}
          changeDeliverySlotLoading={changeDeliverySlotLoading}
          onSubmit={handleOnSubmit}
        />
      )}
    </Stack>
  );
};
export { OrderDetailsPage };
