import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  Switch,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import {
  updateTopCategory,
  updateCategoryStatus,
  updateComingSoonStatus,
} from '@app/modules/categories/slice';
import { Strings } from '@app/constants';
import { useRequestState } from '@app/hooks';
import { selectUpdateSkuStatusState } from '@app/modules/sku/selectors';
import { updateSkuStatus } from '@app/modules/sku/slice';
import {
  selectUpdateCategoryStatusState,
  selectUpdateComingSoonStatusState,
  selectUpdateTopCategoryState,
} from '@app/modules/categories/selectors';

interface CategoryCardProps {
  details: any;
  detailPage?: string;
}

interface ContentItem {
  label?: string;
  value?: string | number | boolean;
}

let content: ContentItem[];
const DetailCard = ({ details, detailPage }: CategoryCardProps) => {
  const dispatch = useDispatch();

  const handleUpdateBannerStatus = (id: number) => {
    dispatch(updateTopCategory({ id }));
  };

  const handleUpdateSkuStatus = (id: number) => {
    dispatch(updateSkuStatus({ id }));
  };

  const handleUpdateActiveStatus = (id: number) => {
    dispatch(updateCategoryStatus({ id }));
  };

  const handleUpdateComingSoonStatus = (id: number) => {
    dispatch(updateComingSoonStatus({ id }));
  };

  useRequestState({
    stateSelector: selectUpdateSkuStatusState,
    successMessageShown: true,
    errorShown: true,
  });

  useRequestState({
    stateSelector: selectUpdateTopCategoryState,
    successMessageShown: true,
    errorShown: true,
  });

  useRequestState({
    stateSelector: selectUpdateCategoryStatusState,
    successMessageShown: true,
    errorShown: true,
  });

  useRequestState({
    stateSelector: selectUpdateComingSoonStatusState,
    successMessageShown: true,
    errorShown: true,
  });

  const aboutUsContent = [
    { label: 'Vision', value: details?.vision ?? 'NA' },
    { label: 'Heading1', value: details?.paragraph1 ?? 'NA' },
    { label: 'Heading2', value: details?.paragraph2 ?? 'NA' },
    { label: 'Heading3', value: details?.paragraph3 ?? 'NA' },
  ];

  const CategoryContent = [
    { label: 'Name', value: details?.name ?? 'NA' },
    { label: 'Parent Category', value: details?.parentCategory?.name ?? 'NA' },
    { label: 'Category Id', value: details?.id ?? 'NA' },
    { label: 'Enabled on App', value: details?.active },
    { label: 'Type', value: details?.type ?? 'NA' },
    { label: 'Top Category', value: details?.topCategory ?? 'NA' },
    { label: 'Coming Soon', value: details?.comingSoon },
  ];
  const CollectionContent = [
    { label: 'Name', value: details?.name ?? 'NA' },
    { label: 'SKU Id', value: details?.skuIds ?? 'NA' },
    { label: 'Active', value: details?.active ? 'Active' : 'Not active' },
  ];
  const SkuContent = [
    { label: 'Name', value: details?.name ?? 'NA' },
    { label: 'Product', value: details?.products?.name ?? 'NA' },
    { label: 'Parent Category', value: details?.products?.category?.parentCategory?.name ?? 'NA' },
    {
      label: 'Category',
      value: details?.products?.category?.name ?? 'NA',
    },
    { label: 'SKU Id', value: details?.skuId ?? 'NA' },
    { label: 'Enabled on App', value: details?.active },
    { label: 'Type', value: details?.type ?? 'NA' },
  ];
  const ProductContent = [
    { label: 'Name', value: details?.name ?? 'NA' },
    { label: 'Category', value: details?.category?.name ?? 'NA' },
    { label: 'Product Id', value: details?.id ?? 'NA' },
    { label: 'Active', value: details?.active ? 'Active' : 'Not active' },
    { label: 'Pet Type', value: details?.pet_type ?? 'NA' },
  ];
  const InventoryContent = [
    { label: 'SKU Name', value: details?.sku?.name ?? 'NA' },
    { label: 'SKU', value: details?.sku?.skuId ?? 'NA' },
    { label: 'Vendor', value: details?.vendor?.firstName ?? 'NA' },
    { label: 'Price', value: details?.price ?? 'NA' },
    { label: 'Our Price', value: details?.ourPrice ?? 'NA' },
    { label: 'Discount Price', value: details?.discountPrice ?? 'NA' },
    { label: 'Stock', value: details?.stock ?? 'NA' },
  ];
  const users = [
    { label: 'Name', value: details?.firstName ?? 'NA' },
    { label: 'Email', value: details?.email ?? 'NA' },
    { label: 'Phone', value: details?.addresses?.[0]?.mobileNumber || details?.phone || 'NA' },
    {
      label: 'User Type',
      value:
        (details?.userType === 'VU' && 'Vendor') || (details?.userType === 'AA' && 'Admin') || 'NA',
    },
    { label: 'Vendor Type', value: details?.vendorType ?? 'NA' },
    { label: 'Status', value: details?.active ? 'Active' : 'InActive' },
    { label: 'Start Time', value: details?.startTime ?? 'NA' },
    { label: 'End Time', value: details?.endTime ?? 'NA' },
    { label: 'Address', value: details?.addresses?.[0]?.address ?? 'NA' },
  ];
  const promotion = [
    { label: 'Name', value: details?.name ?? 'N/A' },
    { label: 'Code', value: details?.code ?? 'N/A' },
    {
      label: 'Start Date',
      value: details?.startDate ? dayjs(details?.startDate).format('DD/MM/YYYY') : '',
    },
    {
      label: 'End Date',
      value: details?.endDate ? dayjs(details?.endDate).format('DD/MM/YYYY') : '',
    },
    { label: 'Discount Amount', value: details?.discountAmount },
    { label: 'Status', value: details?.status ? 'Active' : 'In Active' },
    {
      label: 'Created By',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      value: `${details?.user?.firstName || ''} ${details?.user?.lastName || ''}`.trim() || 'N/A',
    },
    {
      label: 'Created On',
      value: details?.createdAt ? dayjs(details?.createdAt).format('DD/MM/YY hh:mm A') : 'N/A',
    },
  ];
  if (detailPage === 'category') {
    content = CategoryContent;
  } else if (detailPage === 'products') {
    content = ProductContent;
  } else if (detailPage === 'collection') {
    content = CollectionContent;
  } else if (detailPage === 'sku') {
    content = SkuContent;
  } else if (detailPage === 'inventory') {
    content = InventoryContent;
  } else if (detailPage === 'users') {
    content = users;
  } else if (detailPage === 'aboutUs') {
    content = aboutUsContent;
  } else if (detailPage === 'promotion') {
    content = promotion;
  }
  return (
    <Card sx={{ margin: 3 }}>
      <Stack sx={{ flexDirection: { sm: 'column', md: 'row' } }}>
        <CardMedia
          component="img"
          alt="image"
          height="300"
          sx={{ width: 260, alignSelf: { xs: 'center' }, objectFit: 'contain' }}
          image={
            details?.profileImage ??
            details?.images?.[0] ??
            details?.image ??
            '/assets/logo/logoOnly.png'
          }
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', lineHeight: 40 }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {content?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        <Typography component="div" sx={{ fontWeight: 700 }}>
                          {item?.label}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {item?.label !== 'Top Category' && (
                          <Typography component="div">{item?.value}</Typography>
                        )}
                        {item?.label === 'Top Category' && (
                          <Switch
                            checked={details?.topCategory}
                            onChange={() => {
                              handleUpdateBannerStatus(details.id);
                            }}
                          />
                        )}
                        {item?.label === 'Enabled on App' && detailPage === 'sku' && (
                          <Switch
                            checked={details?.active}
                            onChange={() => {
                              handleUpdateSkuStatus(details.id);
                            }}
                          />
                        )}
                        {item?.label === 'Enabled on App' && detailPage === 'category' && (
                          <Switch
                            checked={details?.active}
                            onChange={() => {
                              handleUpdateActiveStatus(details?.id);
                            }}
                          />
                        )}
                        {item?.label === 'Coming Soon' && detailPage === 'category' && (
                          <Switch
                            checked={details?.comingSoon === true}
                            onChange={() => {
                              handleUpdateComingSoonStatus(details?.id);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Box>
      </Stack>
      {(details?.skuAttributes?.length || details?.description || details?.desc) && (
        <Divider sx={{ margin: '0% 0% 2% 0%' }} />
      )}
      {(details?.description || details?.desc) && (
        <Stack m={3} spacing={1} pb={2}>
          <Typography variant="subtitle1">{Strings.detailCard.description}</Typography>
          <Typography variant="body2">{details?.description || details?.desc}</Typography>
        </Stack>
      )}
      {details?.skuAttributes?.[0] && (
        <Stack spacing={3} mx={3} pb={3}>
          {details.skuAttributes?.map(
            (skuAtt: { id: string; attributeValue: string; attribute: { name: string } }) => (
              <Stack key={skuAtt.id} spacing={1}>
                <Stack>
                  <Typography variant="subtitle1">{skuAtt.attribute.name}</Typography>
                </Stack>
                <Stack justifySelf={'start'} flex={1}>
                  <Typography variant="body2">{skuAtt.attributeValue}</Typography>
                </Stack>
              </Stack>
            )
          )}
        </Stack>
      )}
    </Card>
  );
};

export { DetailCard };
