import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Stack, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '@app/components';
import { IMAGE_BASE_URL } from '@app/configs';
import { addAboutUs } from '../slice';
import { useRequestState } from '@app/hooks';
import { selectAddAboutUsState } from '../selector';
import { addAboutUsSchema } from '../validator';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: any;
}

const AddAboutUs = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();
  const resolver = yupResolver(addAboutUsSchema);
  const [initialValues, setInitialValues] = useState<any>(null);

  const methods = useForm<any>({
    resolver,
    defaultValues: {
      vision: get(row, 'vision', ''),
      paragraph1: get(row, 'paragraph1', ''),
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      image: row?.image ? IMAGE_BASE_URL + row.image : '',
      paragraph2: get(row, 'paragraph2', ''),
      paragraph3: get(row, 'paragraph3', ''),
      icon1: get(row, 'icon1', ''),
      icon2: get(row, 'icon2', ''),
      icon3: get(row, 'icon3', ''),
    },
  });

  useEffect(() => {
    methods.reset({
      vision: get(row, 'vision', ''),
      paragraph1: get(row, 'paragraph1', ''),
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      image: row?.image ? IMAGE_BASE_URL + row.image : '',
      paragraph2: get(row, 'paragraph2', ''),
      paragraph3: get(row, 'paragraph3', ''),
      icon1: get(row, 'icon1', ''),
      icon2: get(row, 'icon2', ''),
      icon3: get(row, 'icon3', ''),
    });
  }, [row, methods]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const onSubmit = (form: any) => {
    const hasChanged = JSON.stringify(form) !== JSON.stringify(initialValues);
    if (hasChanged) {
      dispatch(
        addAboutUs({
          ...form,
          id: row?.id,
          image: methods.getValues('image'),
          icon1: methods.getValues('icon1'),
          icon2: methods.getValues('icon2'),
          icon3: methods.getValues('icon3'),
        })
      );
    } else {
      onClose();
    }
  };

  const { loading } = useRequestState({
    stateSelector: selectAddAboutUsState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  useEffect(() => {
    if (row?.id) {
      setInitialValues(methods.getValues());
    }
  }, [row?.id, row]);

  const handleDrop = useCallback(
    (acceptedFiles: File[], name: string) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        methods.setValue(name, newFile, { shouldValidate: true });
        setIsImageUploaded(true);
      }
    },
    [methods]
  );

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header
            variant="dialog"
            title={row?.id ? Strings.pageTitle.editAboutUs : Strings.pageTitle.addAboutUs}
            onClose={onClose}
          />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
                <RHFUploadAvatar
                  name={'image'}
                  onDrop={(acceptedFiles) => {
                    handleDrop(acceptedFiles, 'image');
                  }}
                  sx={{
                    borderRadius: '10px',
                    width: 115,
                    height: 115,
                    border: 'none',
                  }}
                  placeholderSx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                    border:
                      isImageUploaded || row?.images ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
                  }}
                />
                <RHFTextField
                  name="vision"
                  required
                  label={Strings.aboutUs.vision}
                  fullWidth
                  variant="outlined"
                />
              </Stack>

              <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
                <RHFUploadAvatar
                  name={'icon1'}
                  onDrop={(acceptedFiles) => {
                    handleDrop(acceptedFiles, 'icon1');
                  }}
                  sx={{
                    borderRadius: '10px',
                    width: 115,
                    height: 115,
                    border: 'none',
                  }}
                  placeholderSx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                    border:
                      isImageUploaded || row?.images ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
                  }}
                />
                <RHFTextField
                  name="paragraph1"
                  required
                  label={Strings.aboutUs.paragraph1}
                  fullWidth
                  variant="outlined"
                />
              </Stack>

              <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
                <RHFUploadAvatar
                  name={'icon2'}
                  onDrop={(acceptedFiles) => {
                    handleDrop(acceptedFiles, 'icon2');
                  }}
                  sx={{
                    borderRadius: '10px',
                    width: 115,
                    height: 115,
                    border: 'none',
                  }}
                  placeholderSx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                    border:
                      isImageUploaded || row?.images ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
                  }}
                />
                <RHFTextField
                  name="paragraph2"
                  required
                  label={Strings.aboutUs.paragraph2}
                  fullWidth
                  variant="outlined"
                />
              </Stack>

              <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
                <RHFUploadAvatar
                  name={'icon3'}
                  onDrop={(acceptedFiles) => {
                    handleDrop(acceptedFiles, 'icon3');
                  }}
                  sx={{
                    borderRadius: '10px',
                    width: 115,
                    height: 115,
                    border: 'none',
                  }}
                  placeholderSx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                    border:
                      isImageUploaded || row?.images ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
                  }}
                />
                <RHFTextField
                  name="paragraph3"
                  required
                  label={Strings.aboutUs.paragraph3}
                  fullWidth
                  variant="outlined"
                />
              </Stack>
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export { AddAboutUs };
