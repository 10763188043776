import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import { useDialog, useRequestState, useSearch } from '@app/hooks';
import DashboardList from './dashboard-list';
import { getOrder } from '@app/modules/order-management/slice';
import { selectOrderState } from '@app/modules/order-management/selectors';
import { usePageCount } from '@app/hooks/use-page-count';
import { calculateTotalDiscount, calculateTotalPrice, isActiveSlot } from '@app/utils';
import { IDeliverySlot } from '@app/modules/order-management';

const TABLE_HEAD_CSV = [
  { key: 'orderId', label: 'Order Id', align: 'left' },
  { key: 'customer', label: 'Customer', align: 'center', sortable: false },
  { key: 'address', label: 'Address', align: 'left', sortable: false },
  { key: 'skuId', label: 'Sku Id', align: 'left', sortable: false },
  { key: 'skuName', label: 'Sku Name', align: 'left', sortable: false },
  { key: 'vendorName', label: 'Vendor Name', align: 'left', sortable: false },
  { key: 'paymentMode', label: 'Payment Mode', align: 'left' },
  { key: 'qtyOrdered', label: 'Qty Ordered', align: 'left', sortable: false },
  { key: 'ourPrice', label: 'Our Price', align: 'left', sortable: false },
  { key: 'discount', label: 'Discount', align: 'left', sortable: false },
  { key: 'discountAmount', label: 'Discount Amount', align: 'left', sortable: false },
  { key: 'discountedPrice', label: 'Discounted Price', align: 'center', sortable: false },
  { key: 'couponDiscount', label: 'Coupon Discount', align: 'center', sortable: false },
  { key: 'finalPrice', label: 'Final Price', align: 'center', sortable: false },
  { key: 'deliveryCharge', label: 'Delivery Charge', align: 'center', sortable: false },
  { key: 'deliverySlot', label: 'Delivery Slot', align: 'left', sortable: false },
  { key: 'deliveryDate', label: 'Delivery Date', align: 'left', sortable: false },
];

const DashboardPage = () => {
  const tableProps = useTable();
  const dashboardDialog = useDialog();
  const dispatch = useDispatch();
  const page = tableProps.page;
  const { count } = usePageCount(getOrder);

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getOrder({ page, q: query }));
    } else {
      dispatch(getOrder({ page }));
    }
  }, [dispatch, page, query]);

  useEffect(() => {
    dispatch(getOrder({ page }));
  }, [dispatch, page]);

  const { data, loading } = useRequestState({
    stateSelector: selectOrderState,
    errorShown: true,
  });

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const searchProps = useSearch({
    data,
    fields: ['name', 'orderStatus'],
  });

  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      const totalPrice: any = calculateTotalPrice(elem?.orderProducts);
      const totalDiscount: any = calculateTotalDiscount(elem?.orderProducts);
      const activeSlot: IDeliverySlot = isActiveSlot(elem?.orderSlots);
      const Total = totalPrice - totalDiscount;
      const discountPercents = (totalDiscount / totalPrice) * 100;

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const customerAddress = `${elem?.customerAddress?.city}, ${elem?.customerAddress?.country}`;

      const deliveryDate = activeSlot?.deliveryDate
        ? dayjs(activeSlot?.deliveryDate).format('YYYY-MM-DD')
        : 'NA';

      const deliverySlot =
        activeSlot?.slotType === 'normal'
          ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${dayjs(`2022-01-01 ${activeSlot?.deliveryStartTime}`).format('hh:mm A')} - ${dayjs(
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `2022-01-01 ${activeSlot?.deliveryEndTime}`
            ).format('hh:mm A')}`
          : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `Express (${activeSlot?.duration} min)`;

      const finalPrice =
        totalPrice - totalDiscount + activeSlot?.deliveryCharge - elem?.totalCouponDiscount;

      return {
        ...elem,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        customer: `${elem?.customerAddress?.name} ${elem?.customerAddress?.mobileNumber}`,
        //  customerNumber: elem?.customerAddress?.mobileNumber,
        address: customerAddress,
        skuId: elem?.orderProducts[0]?.sku?.id,
        skuName: elem?.orderProducts[0]?.sku?.name,
        vendorName: elem?.orderProducts[0]?.vendorAddress?.name,
        paymentMode: elem?.paymentMode,
        qtyOrdered: elem?.orderProducts?.length,
        ourPrice: totalPrice,
        discount: discountPercents?.toFixed(2),
        discountAmount: totalDiscount,
        discountedPrice: Total?.toFixed(2),
        couponDiscount: elem?.totalCouponDiscount?.toFixed(2),
        finalPrice: finalPrice?.toFixed(2),
        deliverySlot,
        deliveryCharge: activeSlot?.deliveryCharge,
        deliveryDate,
      };
    });
    return serializeData;
  };

  return (
    <>
      <Header
        title={Strings.pageTitle.dashboard}
        helperText={''}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
        onClickActionButton={dashboardDialog.show}
        data={flattenData(data)}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'dashboard-list.csv'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
        orientation={'landscape'}
      />

      <DashboardList
        loading={loading}
        tableProps={tableProps}
        rows={searchProps.filteredRows}
        count={count}
      />
    </>
  );
};

export { DashboardPage };
