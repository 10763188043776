import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { TableCellCustom } from '@app/components/table';
import { IDashboard } from '../types';
import { TruncatedTypography } from '@app/components/typography';
import { calculateTotalDiscount, calculateTotalPrice, isActiveSlot } from '@app/utils';
import { IDeliverySlot } from '@app/modules/order-management';

interface Props {
  row?: IDashboard;
  selected: boolean;
  onSelectRow?: VoidFunction;
}

const DashboardRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const totalPrice: any = calculateTotalPrice(row?.orderProducts);
  const totalDiscount: any = calculateTotalDiscount(row?.orderProducts);
  const activeSlot: IDeliverySlot = isActiveSlot(row?.orderSlots);

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const Total = totalPrice - totalDiscount;

  const discountPercents = (totalDiscount / totalPrice) * 100;

  const slotDetail =
    activeSlot?.slotType === 'normal'
      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${dayjs(`2022-01-01 ${activeSlot?.deliveryStartTime}`).format('hh:mm A')} - ${dayjs(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `2022-01-01 ${activeSlot?.deliveryEndTime}`
        ).format('hh:mm A')}`
      : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `Express (${activeSlot?.duration} min)`;

  return (
    <>
      <TableCellCustom onClick={onSelectRow}>
        <Stack width="45px">
          <Typography variant="body2">{row?.id}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} fullWidth>
        <Stack width="160px">
          <Typography variant="body2">{row?.orderId}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="100px">
          <Typography variant="body2">{row?.customerAddress?.name ?? 'NA'} </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.customerAddress?.mobileNumber ?? 'NA'} </Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow} fullWidth>
        <Stack width="150px">
          <TruncatedTypography text={`${(row?.customerAddress?.city as string) ?? 'NA'},`} />
          <TruncatedTypography text={`${(row?.customerAddress?.country as string) ?? 'NA'},`} />
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.orderProducts[0]?.sku?.skuId}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center" fullWidth>
        <Stack width="150px">
          <TruncatedTypography
            text={`${(row?.orderProducts[0]?.sku?.name as string) ?? 'Sku Name'},`}
          />
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width={'100px'}>
          <TruncatedTypography variant="body2" text={row?.orderProducts[0]?.vendorAddress?.name} />
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="left">
        <Stack width="150px">
          <Typography variant="body2">{row?.paymentMode}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.orderProducts?.length}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="70px">
          <Typography variant="body2">AED {totalPrice}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{discountPercents?.toFixed(2)}%</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="70px">
          <Typography variant="body2">AED {totalDiscount}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="70px">
          <Typography variant="body2">AED {Total?.toFixed(2)}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="80px">
          <Typography variant="body2">
            AED {row?.totalCouponDiscount?.toFixed(2) ?? 'N/A'}
          </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">
          AED{' '}
          {Total !== undefined && row?.totalCouponDiscount !== undefined
            ? (Total - row.totalCouponDiscount).toFixed(2)
            : 'N/A'}
        </Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="80px">
          <Typography variant="body2">
            {activeSlot?.deliveryDate ? dayjs(activeSlot?.deliveryDate).format('YYYY-MM-DD') : 'NA'}
          </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="80px">
          <Typography variant="body2">AED {activeSlot?.deliveryCharge}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="80px">
          <Typography variant="body2">{slotDetail}</Typography>
        </Stack>
      </TableCellCustom>
    </>
  );
};

export { DashboardRow };
