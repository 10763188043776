import { Card, CardMedia, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import { AddAboutUs } from '@app/modules/about-us/view/add-about-us';
import { selectAboutUsState } from '@app/modules/about-us/selector';
import { getAboutUs } from '@app/modules/about-us/slice';
import { CustomAvatar } from '@app/components/custom-avatar';
import { IMAGE_BASE_URL } from '@app/configs';

const AboutUsDetailPage = () => {
  const editDialog = useDialog();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { data } = useRequestState({
    stateSelector: selectAboutUsState,
    errorShown: true,
  });

  useEffect(() => {
    dispatch(getAboutUs());
  }, [dispatch]);

  return (
    <>
      <Stack sx={{ height: '100vh' }}>
        <Header
          title={Strings.pageTitle.aboutUsDetail}
          actionButtonTitle={data?.length > 0 ? Strings.button.edit : Strings.button.addAboutUs}
          onClickActionButton={editDialog.show}
        />
        {data?.length > 0 ? (
          <Stack>
            <Card sx={{ padding: 1, mx: 10 }}>
              <Stack>
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 5,
                    gap: 10,
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="image"
                    height="300"
                    width="500"
                    sx={{
                      width: 200,
                      height: 150,
                      alignSelf: { xs: 'center' },
                      objectFit: 'fill',
                      borderRadius: '16px',
                      border: '1.5px solid grey',
                    }}
                    image={data?.[0]?.image}
                  />

                  <Stack
                    sx={{
                      height: '100%',
                      width: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontWeight: 600 }}>{Strings.aboutUs.vision}</Typography>
                    <Typography sx={{ color: '#5F5F5F' }}>{data?.[0]?.vision}</Typography>
                  </Stack>
                </Stack>
                <Divider sx={{ px: 10, mx: 2, mb: 1 }} />
                <Stack sx={{ gap: 1.5 }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 2,
                      px: 4,
                    }}
                  >
                    <CustomAvatar
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      sx={{ height: 50, width: 50 }}
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      src={IMAGE_BASE_URL + data?.[0].icon1}
                    />
                    <Stack>
                      <Typography sx={{ color: theme.palette.grey[700] }}>
                        {data?.[0].paragraph1}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider sx={{ mx: 2 }} />
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 2,
                      px: 4,
                    }}
                  >
                    <CustomAvatar
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      sx={{ height: 50, width: 50 }}
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      src={IMAGE_BASE_URL + data?.[0].icon2}
                    />
                    <Stack>
                      <Typography sx={{ color: theme.palette.grey[700] }}>
                        {data?.[0].paragraph2}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider sx={{ mx: 2 }} />
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 2,
                      px: 4,
                    }}
                  >
                    <CustomAvatar
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      sx={{ height: 50, width: 50 }}
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      src={IMAGE_BASE_URL + data?.[0].icon3}
                    />
                    <Stack>
                      <Typography sx={{ color: theme.palette.grey[700] }}>
                        {data?.[0].paragraph3}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        ) : (
          <Stack
            sx={{
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack
              sx={{
                background: `url(/assets/background/add-details.png) bottom center/cover`,
                height: 350,
                width: 350,
              }}
            />
          </Stack>
        )}
      </Stack>

      <AddAboutUs
        open={editDialog.visible}
        onClose={editDialog.hide}
        row={data?.length > 0 ? data?.[0] : []}
      />
    </>
  );
};

export { AboutUsDetailPage };
