import { Card, Stack, Typography } from '@mui/material';

import { PaginatedTable } from '@app/components';
import { TableProps } from '@app/components/table';
import { IOrderListPage } from '../types';
import { OrderDetailRow } from './order-detail-row';
import { Strings } from '@app/constants';
import { InventoryTransaction } from './inventory-transaction';
import { CustomerDetailCard } from './customer-detail-card';
import { PriceDetailCard } from './price-detail-card';
import { isActiveSlot } from '@app/utils';

const TABLE_HEAD = [
  { id: 'product', label: 'Product', align: 'center', sortable: false },
  { id: 'description', label: 'Description', align: 'center', sortable: false },
  { id: 'quantity', label: 'Quantity', align: 'center', sortable: false },
  { id: 'price', label: 'Price', align: 'center', sortable: false },
  { id: 'discount', label: 'Discount', align: 'center', sortable: false },
  { id: 'finalPrice', label: 'Final Price', align: 'center', sortable: false },
  { id: 'totalPrice', label: 'Total Price', align: 'center', sortable: false },
];

interface Props {
  tableProps: TableProps;
  loading: boolean;
  data: any;
}

const OrderDetails = ({ data, tableProps, loading }: Props) => {
  const activeSlot = isActiveSlot(data?.orderSlots);

  return (
    <Stack mb={5}>
      <Card sx={{ width: '95%', alignSelf: 'center', marginTop: '2%', borderRadius: 0 }}>
        <Stack sx={{ backgroundColor: '#F4F6F8', padding: '2% 0% 2% 0%' }}>
          <Typography variant="h5" ml={5}>
            {Strings.detailCard.orderDetails}
          </Typography>
        </Stack>
        <PaginatedTable<IOrderListPage>
          head={TABLE_HEAD}
          data={data?.orderProducts}
          tableProps={tableProps}
          loading={loading}
          isServerSidePaging={false}
          paginationShown={false}
        >
          {(row) => (
            <OrderDetailRow
              row={row}
              selected={tableProps.selected.includes(row.id)}
              onSelectRow={() => {
                ('');
              }}
            />
          )}
        </PaginatedTable>
      </Card>
      <Card
        sx={{
          width: '95%',
          marginTop: '2%',
          alignSelf: 'center',
          padding: '2% 2% 2% 2%',
          borderRadius: 0,
          backgroundColor: '#F7F7F7',
        }}
      >
        <Stack flexDirection="row" gap={2}>
          <CustomerDetailCard customer={data?.customerAddress} />
          <PriceDetailCard
            productDetails={data?.orderProducts}
            orderSlots={activeSlot}
            paymentMode={data?.paymentMode}
            couponDiscount={data?.totalCouponDiscount}
          />
        </Stack>
      </Card>
      <InventoryTransaction rows={data?.orderProducts} tableProps={tableProps} loading={loading} />
    </Stack>
  );
};
export { OrderDetails };
