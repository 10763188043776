import { Stack, Card, Typography, Divider } from '@mui/material';

import { Strings } from '@app/constants';
import { IOrderSlots, IProduct } from '../types';
import { calculateTotalDiscount, calculateTotalPrice } from '@app/utils';

interface Props {
  productDetails: IProduct[];
  orderSlots: IOrderSlots;
  paymentMode?: string;
  couponDiscount?: number;
}

const PriceDetailCard = ({
  productDetails,
  orderSlots,
  paymentMode,
  couponDiscount = 0,
}: Props) => {
  const totalPrice: any = calculateTotalPrice(productDetails) ?? 0;
  const totalDiscount: any = calculateTotalDiscount(productDetails) ?? 0;

  const finalPrice = totalPrice - totalDiscount + orderSlots?.deliveryCharge - couponDiscount;

  return (
    <Card sx={{ padding: '2% 2% 5% 2%', width: '50%', borderRadius: '5px' }}>
      <Typography variant="h6">{Strings.detailCard.priceDetails}</Typography>
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack mt={2} gap={1.5}>
          <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.paymentMode}</Typography>
          <Typography sx={{ fontSize: '14px' }}>
            {`${Strings.detailCard.price(productDetails?.length)} ${
              productDetails?.length > 1 ? 'items)' : 'item)'
            }`}
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.productDiscount}</Typography>
          {Number(couponDiscount) !== 0 && (
            <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.voucherDiscount}</Typography>
          )}

          <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.deliveryCharges}</Typography>
        </Stack>
        <Stack mt={2} gap={1.5} ml={4}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}> {paymentMode}</Typography>
          <Typography sx={{ fontSize: '14px' }}>
            {' '}
            AED {Number(totalPrice).toFixed(2) ?? 0}
          </Typography>
          <Typography sx={{ color: '#F39200', fontSize: '14px' }}>
            - AED {Number(totalDiscount)?.toFixed(2) ?? 0}
          </Typography>
          {Number(couponDiscount) !== 0 && (
            <Typography sx={{ color: '#F39200', fontSize: '14px' }}>
              - AED {Number(couponDiscount).toFixed(2) ?? 0}
            </Typography>
          )}
          <Typography sx={{ color: '#F39200', fontSize: '14px' }}>
            {orderSlots?.deliveryCharge ? `+ AED ${orderSlots?.deliveryCharge}` : 'FREE DELIVERY'}
          </Typography>
        </Stack>
      </Stack>
      <Stack mt={3}>
        <Divider />
        <Stack flexDirection="row" mt={2} mb={2} justifyContent="space-between">
          <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
            {Strings.detailCard.totalAmount}
          </Typography>
          <Typography variant="subtitle1">AED {Number(finalPrice).toFixed(2) ?? 0}</Typography>
        </Stack>
      </Stack>
      <Divider />
    </Card>
  );
};
export { PriceDetailCard };
