/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  addAboutUs,
  addAboutUsFailed,
  addAboutUsSuccess,
  getAboutUs,
  getAboutUsFailed,
  getAboutUsSuccess,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';

// ----------------------------------------
function* getAboutUsSagas(action: ReturnType<typeof getAboutUs>): any {
  try {
    const result = yield call(request.get, endpoints.getAboutUs);
    yield put(setPageCount({ id: action.type, count: result.data.total }));
    yield put(getAboutUsSuccess(result.data.data));
  } catch (err: any) {
    yield put(getAboutUsFailed(err));
  }
}
//
function* addAboutUsSagas(action: ReturnType<typeof addAboutUs>): any {
  try {
    const { image, paragraph1, paragraph2, paragraph3, vision, id, icon1, icon2, icon3 } =
      action.payload;

    const formData = new FormData();

    formData.append('paragraph1', paragraph1);
    formData.append('paragraph2', paragraph2);
    formData.append('paragraph3', paragraph3);
    formData.append('vision', vision);

    if (typeof image === 'object') {
      formData.append('image', image);
    }

    if (typeof icon1 === 'object') {
      formData.append('icon1', icon1);
    }

    if (typeof icon2 === 'object') {
      formData.append('icon2', icon2);
    }

    if (typeof icon3 === 'object') {
      formData.append('icon3', icon3);
    }

    let result;
    if (id) {
      result = yield call(request.put, endpoints.updateAboutUs + id, formData);
      yield put(getAboutUs());
    } else {
      result = yield call(request.post, endpoints.addAboutUs, formData);
    }
    yield put(addAboutUsSuccess(result.data));
  } catch (err: any) {
    yield put(addAboutUsFailed(err));
  }
}

function* aboutUsSagas() {
  yield all([
    takeLatest([getAboutUs, addAboutUsSuccess], getAboutUsSagas),
    takeLatest([addAboutUs], addAboutUsSagas),
  ]);
}

export { aboutUsSagas };
