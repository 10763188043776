import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed } from '@app/types';
import { IAboutUsState } from './types';

const initialState: IAboutUsState = {
  aboutUs: {},
  addAboutUs: {},
};

const slice = createSlice({
  name: 'aboutUs',
  initialState,
  reducers: {
    getAboutUs(state) {
      state.aboutUs.status = RequestStatus.RUNNING;
    },
    getAboutUsSuccess(state, action: PayloadAction<any>) {
      state.aboutUs.status = RequestStatus.SUCCESS;
      state.aboutUs.data = action.payload;
    },
    getAboutUsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.aboutUs.status = RequestStatus.ERROR;
      state.aboutUs.error = action.payload;
    },
    //
    addAboutUs(state, action: PayloadAction<any>) {
      state.addAboutUs.status = RequestStatus.RUNNING;
    },
    addAboutUsSuccess(state, action: PayloadAction<any>) {
      state.addAboutUs.status = RequestStatus.SUCCESS;
      state.addAboutUs.data = action.payload;
    },
    addAboutUsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addAboutUs.status = RequestStatus.ERROR;
      state.addAboutUs.error = action.payload;
    },
    //
  },
});
export default slice.reducer;

export const {
  getAboutUs,
  getAboutUsSuccess,
  getAboutUsFailed,
  addAboutUs,
  addAboutUsFailed,
  addAboutUsSuccess,
} = slice.actions;
