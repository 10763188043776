import { TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

type DatePickerProps = {
  name: string;
  label: string;
  minDate?: any;
  maxDate?: any;
  onChange?: (date: any) => void;
  defaultValue?: Dayjs | null;
  disabled?: boolean;
} & TextFieldProps;

function RHFDatePicker({
  name,
  label,
  sx,
  minDate,
  maxDate,
  onChange,
  required,
  defaultValue,
  disabled,
}: DatePickerProps) {
  const { control, setValue, trigger } = useFormContext();

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    defaultValue ? dayjs(defaultValue, 'DD/MM/YYYY') : null
  );

  const handleDateSelection = async (date: Dayjs | null) => {
    if (date) {
      setSelectedDate(date);
      setValue(name, date.format('DD/MM/YYYY'));
      await trigger(name);
      if (onChange) {
        onChange(date);
      }
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <DatePicker
                {...field}
                minDate={minDate ?? undefined}
                maxDate={maxDate ?? undefined}
                sx={sx}
                value={selectedDate}
                disabled={disabled}
                onChange={handleDateSelection}
                label={label}
                slotProps={{
                  textField: () => ({
                    error: !!error,
                    focused: false,
                    helperText: error ? error.message : null,
                    required,
                  }),
                }}
              />
            </>
          );
        }}
      />
    </>
  );
}

export { RHFDatePicker };
