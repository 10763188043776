/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormContext, Controller } from 'react-hook-form';
import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
  type RadioGroupProps,
  FormControlLabel,
} from '@mui/material';

// ----------------------------------------------------------------------

type Props = RadioGroupProps & {
  name: string;
  options: Array<{ label: React.ReactNode; value: any; disabled?: boolean }>;
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
  radioSx?: any;
  formLabelSx?: any;
  optionValueType?: any;
};

export function RHFRadioGroup({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  radioSx,
  formLabelSx,
  optionValueType,
  ...other
}: Props) {
  const { control } = useFormContext();

  const labelledby = label ? `${name}-${label}` : '';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" id={labelledby} sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <RadioGroup
            {...field}
            onChange={(event) => {
              field.onChange(optionValueType === 'number' ? Number(event.target.value) : event);
            }}
            aria-labelledby={labelledby}
            row={row}
            {...other}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio sx={radioSx} />}
                label={option.label}
                sx={{
                  '&:not(:last-of-type)': {
                    mb: spacing ?? 0,
                  },
                  ...(row && {
                    mr: 0,
                    '&:not(:last-of-type)': {
                      mr: spacing ?? 2,
                    },
                  }),
                  ...formLabelSx,
                }}
                disabled={option.disabled ?? false}
              />
            ))}
          </RadioGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
