/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { TableCellCustom } from '@app/components/table';
import { TruncatedTypography } from '@app/components/typography';
import { useRequestState } from '@app/hooks';
import { updatePromotionStatus } from '../slice';
import { selectUpdatePromotionStatus } from '../selector';
import { IVoucher } from '../types';

interface Props {
  row?: IVoucher;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const PromotionRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(row?.status);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    dispatch(updatePromotionStatus({ id: row?.id }));
  };

  useRequestState({
    stateSelector: selectUpdatePromotionStatus,
    successMessageShown: true,
  });

  return (
    <>
      <TableCellCustom onClick={onSelectRow}>
        <TruncatedTypography text={row?.code ?? 'NA'} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} fullWidth>
        <Typography variant="body2">{row?.name ?? 'N/A'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} fullWidth>
        <TruncatedTypography text={row?.description ?? 'N/A'} />
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow} fullWidth>
        <TruncatedTypography text={row?.voucherType ?? 'NA'} />
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={row?.discountType ?? 'N/A'} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center" fullWidth>
        <Typography>{row?.category ?? 'N/A'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center" fullWidth>
        <TruncatedTypography text={row?.country ?? 'N/A'} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography variant="body2" text={row?.currency ?? 'N/A'} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.totalUsageLimit ?? 'N/A'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.totalUses ?? 'N/A'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">
          {(row?.totalUsageLimit ?? 0) - (row?.totalUses ?? 0)}
        </Typography>
      </TableCellCustom>

      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.usagePerUserLimit ?? 'N/A'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.maxDiscountAmount ?? 'N/A'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.minimumOrderValue ?? 'N/A'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">
          {row?.startDate ? dayjs(row?.startDate).format('DD/MM/YYYY') : 'N/A'}
        </Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">
          {row?.startDate ? dayjs(row?.endDate).format('DD/MM/YYYY') : 'N/A'}
        </Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">
          {`${row?.user?.firstName ?? ''} ${row?.user?.lastName ?? ''}`.trim() || 'N/A'}
        </Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center" fullWidth>
        <Stack width="120px">
          <Typography variant="body2">
            {row?.createdAt ? dayjs(row?.createdAt).format('DD/MM/YY hh:mm A') : 'N/A'}
          </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex align="center">
        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
      </TableCellCustom>
    </>
  );
};

export { PromotionRow };
