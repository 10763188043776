import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------addCategorySchema-------------
export const addAboutUsSchema = Yup.object()
  .shape({
    vision: Yup.string().required(Strings.validation.fieldRequired(Strings.aboutUs.vision)),
    image: Yup.string().required(Strings.validation.uploadRequired(Strings.field.image)),
    paragraph1: Yup.string().required(Strings.validation.fieldRequired(Strings.aboutUs.paragraph1)),
    paragraph2: Yup.string().required(Strings.validation.fieldRequired(Strings.aboutUs.paragraph2)),
    paragraph3: Yup.string().required(Strings.validation.fieldRequired(Strings.aboutUs.paragraph3)),
    icon1: Yup.string().required(Strings.validation.uploadRequired(Strings.field.image)),
    icon2: Yup.string().required(Strings.validation.uploadRequired(Strings.field.image)),
    icon3: Yup.string().required(Strings.validation.uploadRequired(Strings.field.image)),
  })
  .required();
