import { Typography } from '@mui/material';

import { TableCellCustom } from '@app/components/table';

interface Props {
  row?: any;
  selected: boolean;
  onSelectRow: VoidFunction;
}
const OrderRow = (props: Props) => {
  const { row, onSelectRow } = props;

  return (
    <>
      <TableCellCustom onClick={onSelectRow} width={'25%'} align="center">
        <Typography variant="body2">{row?.sku.id}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} width={'25%'} align="center">
        <Typography variant="body2">{row?.sku.skuId}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.vendor?.firstName ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.quantity}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.ourPrice ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.discountPrice ?? 'NA'}</Typography>
      </TableCellCustom>
    </>
  );
};
export { OrderRow };
