import { useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { DetailCard } from '@app/components/detail-card';
import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useRequestState } from '@app/hooks';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { selectGetPromotionDetails } from '@app/modules/promotion/selector';
import { getPromotionDetails } from '@app/modules/promotion/slice';

const PromotionDetailPage = () => {
  const dispatch = useDispatch();

  const { state } = useLocation();
  const navigation = useNavigate();

  const { data } = useRequestState({
    stateSelector: selectGetPromotionDetails,
    errorShown: true,
  });

  useEffect(() => {
    dispatch(getPromotionDetails({ id: state?.id }));
  }, [state, dispatch]);

  const handleBack = () => {
    window.history.back();
  };

  return (
    <>
      <Stack>
        <Header
          backButton
          title={Strings.pageTitle.promotionDetail}
          onClickBack={() => {
            handleBack();
          }}
          actionButtonTitle={Strings.button.edit}
          onClickActionButton={() => {
            navigation(PATH_DASHBOARD.addPromotion, { state: { data } });
          }}
        />
        <DetailCard detailPage={'promotion'} details={data} />
      </Stack>
    </>
  );
};

export { PromotionDetailPage };
