import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import * as Configs from '@app/configs';
import DashboardLayout from '@app/layouts/dashboard';
import { AuthGuard, GuestGuard } from '@app/guard';
import { Page404 } from '@app/components';
import { LoginPage, SetPasswordPage, ForgotPasswordPage } from '@app/modules/auth';
import { CategoryListPage } from '@app/modules/categories';
import { UserListPage, ViewUser } from '@app/modules/users';
import { ServiceListPage } from '@app/modules/service';
import { DashboardPage } from '@app/modules/dashboard';
import { ProductListPage } from '@app/modules/products';
import { PATH_AUTH, PATH_DASHBOARD } from './paths';
import { InventoryListPage } from '@app/modules/inventory-banner-management';
import { SkuListPage } from '@app/modules/sku';
import { AttributeListPage } from '@app/modules/attribute';
import { BannerPage } from '@app/modules/banner/view/banner-page';
import { CollectionPage } from '@app/modules/collection/view/collection-page';
import { BannerDetailPage } from '@app/modules/banner-detail';
import { ProductDetailPage } from '@app/modules/product-detail/view/product-detail-page';
import { CategoryDetailPage } from '@app/modules/category-detail';
import { InventoryDetailPage } from '@app/modules/inventory-detail';
import { CollectionDetailPage } from '@app/modules/collection-detail';
import { SlotManagementPage } from '@app/modules/slot-management';
import { SlotManagementDetailPage } from '@app/modules/slot-management-detail';
import { SkuDetailPage } from '@app/modules/sku-detail';
import { OrderListPage } from '@app/modules/order-management';
import { OrderDetailsPage } from '@app/modules/order-detail';
import { CustomerListPage } from '@app/modules/customers';
import { AboutUsDetailPage } from '@app/modules/about-us-detail';
import { AddPromotion, PromotionListPage } from '@app/modules/promotion';
import { PromotionDetailPage } from '@app/modules/promotion-details';

// ----------------------------------------------------------------------

export function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <Navigate to={Configs.PATH_AFTER_LOGIN} />
        </AuthGuard>
      ),
    },
    {
      element: (
        <GuestGuard>
          <Outlet />
        </GuestGuard>
      ),
      children: [
        {
          path: PATH_AUTH.login,
          element: <LoginPage />,
        },
        {
          path: PATH_AUTH.forgotPassword,
          element: <ForgotPasswordPage />,
        },
        {
          path: PATH_AUTH.setPassword,
          element: <SetPasswordPage />,
        },
        {
          path: '*',
          element: <Page404 />,
        },
      ],
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={Configs.PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: PATH_DASHBOARD.categories,
          element: <CategoryListPage />,
        },
        {
          path: PATH_DASHBOARD.app,
          element: <DashboardPage />,
        },
        {
          path: PATH_DASHBOARD.users,
          element: <UserListPage />,
        },
        {
          path: PATH_DASHBOARD.user,
          element: <ViewUser />,
        },
        {
          path: PATH_DASHBOARD.service,
          element: <ServiceListPage />,
        },
        {
          path: PATH_DASHBOARD.product,
          element: <ProductListPage />,
        },
        {
          path: PATH_DASHBOARD.sku,
          element: <SkuListPage />,
        },
        {
          path: PATH_DASHBOARD.order,
          element: <OrderListPage />,
        },
        {
          path: PATH_DASHBOARD.attribute,
          element: <AttributeListPage />,
        },
        {
          path: PATH_DASHBOARD.inventoryBannerManagement,
          element: <InventoryListPage />,
        },
        {
          path: PATH_DASHBOARD.banner,
          element: <BannerPage />,
        },
        {
          path: PATH_DASHBOARD.collection,
          element: <CollectionPage />,
        },
        {
          path: PATH_DASHBOARD.bannerDetail,
          element: <BannerDetailPage />,
        },
        {
          path: PATH_DASHBOARD.productDetail,
          element: <ProductDetailPage />,
        },
        {
          path: PATH_DASHBOARD.categoryDetail,
          element: <CategoryDetailPage />,
        },
        {
          path: PATH_DASHBOARD.inventoryDetail,
          element: <InventoryDetailPage />,
        },
        {
          path: PATH_DASHBOARD.collectionDetail,
          element: <CollectionDetailPage />,
        },
        {
          path: PATH_DASHBOARD.slotManagement,
          element: <SlotManagementPage />,
        },
        {
          path: PATH_DASHBOARD.slotManagementDetail,
          element: <SlotManagementDetailPage />,
        },
        {
          path: PATH_DASHBOARD.skuDetail,
          element: <SkuDetailPage />,
        },
        {
          path: PATH_DASHBOARD.orderDetail,
          element: <OrderDetailsPage />,
        },
        {
          path: PATH_DASHBOARD.customers,
          element: <CustomerListPage />,
        },
        {
          path: PATH_DASHBOARD.aboutUsDetail,
          element: <AboutUsDetailPage />,
        },
        {
          path: PATH_DASHBOARD.promotion,
          element: <PromotionListPage />,
        },
        {
          path: PATH_DASHBOARD.addPromotion,
          element: <AddPromotion />,
        },
        {
          path: PATH_DASHBOARD.promotionDetail,
          element: <PromotionDetailPage />,
        },
        {
          path: '*',
          element: <Page404 />,
        },
      ],
    },
  ]);
}
